.our-brands {
  background-color: #ffffff;

  .container {
    max-width: 1440px;
    padding: 0 140px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 0 36px;
    }

    h2.title {
      margin-top: 159.3px;
      text-align: center;
      font-family: 'Cormorant Garamond';
      font-weight: 400;
      font-size: 45px;
      line-height: 70px;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 29px;

      @media(max-width:768px) {
        margin-top: 71.3px;
        margin-bottom: 22px;
        font-size: 34px;
      }
    }

    p.description {
      text-align: center;
      font-family: 'Inter';
      font-weight: 300;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 51.7px;
      max-width: 782px;
      margin-left: auto;
      margin-right: auto;

      @media(max-width:768px) {
        margin-bottom: 23px;
      }
    }

    .logos-box {
      display: grid;
      grid-template-columns: repeat(6, auto);
      column-gap: 59px;
      justify-content: center;
      align-items: center;

      @media(max-width:1024px) {
        grid-template-columns: repeat(auto-fit, 6em);
      }

      @media(max-width:768px) {
        grid-template-columns: repeat(3, auto);
        column-gap: 20px;
      }
    }

    .transparent-btn {
      display: flex;
      padding-top: 14px;
      padding-bottom: 13px;
      width: 200px;
      height: 44px;
      border: 2px solid #7990a9;
      font-size: 14px;
      font-weight: bold;
      line-height: 38px;
      font-family: 'Inter';
      text-align: center;
      letter-spacing: 1.54px;
      color: #7990a9;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      // margin-bottom: 235px;
      text-transform: uppercase;
      background: linear-gradient(
        120deg,
        #7990a9 50%,
        rgba(255, 255, 255, 0) 50%
      );
      transition: all 0.6s ease-out;
      background-size: 250%;
      background-position-x: 100%;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      margin-bottom: 172px;

      &:hover {
        color: white;
        background-position-x: 0%;
      }

      @media (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 55px;
        margin-bottom: 105px;
      }

      @media (max-width: 390px) {
        width: 100%;
      }
    }
  }
}
